body
  background-color: #f6f6f6

.Page
  background-color: #fff
  margin: 80px 0
  padding: 60px 80px

  .title
    font-weight: lighter
    font-size: 36px
    text-align: center
    margin-top: 35px
    margin-bottom: 25px

  .skillsColumn
    padding: 0 60px
    justify-content: center !important

@media (min-width: 639px)
  .Page
    margin: 0

@media (max-width: 639px)
  .Page
    padding: 40px 20px
    margin: 0

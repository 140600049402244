.Story
  display: flex
  flex-direction: column
  &.reverse
    flex-flow: column-reverse
    & .StoryPoint:not(:last-of-type) .Line
      height: unset
      min-height: unset

      width: unset
      margin: unset
      background-color: unset
    & .StoryPoint:not(:first-of-type) .Line
      height: calc(100% - 30px)
      min-height: 20px
      width: 2px
      margin: 15px 50% 0 50%
      background-color: #b9b9b9

.StoryPoint
  margin-top: 10px

  h1
    font-size: 30px
    font-weight: lighter

  .Side
    display: flex
    flex-direction: column
    margin: 0 30px
    height: 100%

    .LineContainer
      flex-basis: 0
      -webkit-box-flex: 1
      flex-grow: 1

  &:not(:last-of-type)
    .Line
      height: calc(100% - 30px)
      min-height: 20px
      width: 2px
      margin: 15px 50% 0 50%
      background-color: #b9b9b9

  .Content
    margin-bottom: 25px

    h1, h3, p
      margin: 0
      font-weight: lighter

    h1
      font-size: 30px

    h3
      font-size: 14px
      min-height: 14px
      margin-bottom: 7px

    p.sub
      margin-left: 20px
      margin-bottom: 14px

  &.sm
    h1
      font-size: 24px
      margin-top: 24px

    img
      height: 39px
      width: 42px
      margin: 20px 0

  .Logo
    display: flex
    justify-content: center
    align-content: center
    width: 80px
    height: 80px

    .Side .LineContainer .Line
      height: calc(100% - 10px)
      min-height: 30px
      margin: 0 50% 10px 50%

@media (max-width: 639px)
  h1,h3
    text-align: center
  .Line
    display: none
  .StoryPoint
    .Content
      margin-top: 25px
      h3
        margin-bottom: 15px
    &.sm
      .Logo
        width: 40px
        height: 40px
        margin: 0
      img
        margin: 0
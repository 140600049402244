.Heading
  .pic
    border-radius: 50%
    box-shadow: 3px 3px 7px 1px rgba(0,0,0,0.2)
  .contacts
    margin-top: 32px
    a
      color: #545454
      margin-right: 5px
      font-weight: lighter
      text-align: end !important
      text-decoration: none
    &>div
      padding: 0
      margin: 0 2px
  .headings
    h1, h2, h3
      margin: 0
      font-weight: lighter
      text-align: end !important
    h1
      font-size: 64px
    h2
      color: #545454
      font-size: 24px
    h3
      color: #545454
      font-size: 22px

@media (max-width: 639px)
  .Heading
    .headings
      h1
        font-size: 48px
@media (max-width: 768px)
  .Heading
    .contacts
      justify-content: center !important
    .headings

      h1,h3,h2
        text-align: center
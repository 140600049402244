.Skill
  width: 220px
  padding-top: 25px

  .logo > img
    width: 24px
    height: 22px
    display: inline

  h5
    font-size: 28px
    font-weight: lighter
    margin: 10px
    display: inline

  .content
    margin-left: 39px
    p
      font-size: 18px
      font-weight: lighter
    span

      font-size: 15px
      color: #383838



@media (max-width: 639px)
  .Skill
    margin: 0

@media (min-width: 994px)
  .Skill
    margin: 0 50px


